<template>
  <div id="Message">
    <div class="Message_in">
      <!-- <div class="Message_top">
        <img
          class="logopc"
          src="~/assets/images/nav/logo.png"
          alt="找不到资源"
          @click="toindex"
        />
        <img
          class="logophone"
          src="~/assets/images/images_phone_show/logo_mini.png"
          alt="找不到资源"
          @click="toindex"
        />
        <span class="gang">|</span>
        <h2>消息中心</h2>
      </div> -->
      <nav-tab-part1 pagetitle='消息中心'>
        <template v-slot:topRightText><span @click="goBack()">返回</span></template>
      </nav-tab-part1>
      <div class="main">
        <div class="msgContent" v-show="MsgList.length">
          <div class="msgleft" @scroll.passive="getScroll($event)">
            <div 
            v-for="(item,index) in MsgList"
            class="msglist"
            :class=" (isActive==index) ? 'msg_isActive':''"
            :key="index"
            @click="lookmsg(index)"
            >
            <i v-show="!item.IsRead"></i>
            <span>
              {{item.MessageTypeName}}
              </span></div>
          </div>
          <div class="msgright" style="display:flex;justify-content: center;align-items:center;" v-show="!singleMsg">
            <div class="nodata">
              <img src="~/assets/images/empty.png" alt="">
              <div>暂无消息</div>
            </div>
          </div>
          <transition name="el-zoom-in-top">
            <div class="msgright" v-show="singleMsg">
              <div class="msg_top">{{singleMsg.MessageTypeName}}</div>
              <div class="msg_middle" v-html="singleMsg.MessageContent"></div>
              <div class="msg_date">{{singleMsg.SendTimeDisplay}}</div>
            </div>
          </transition>
        </div>
        <div class="msgContent1"  v-show="!MsgList.length">
          暂无消息
        </div>
      </div>
      <footer-page></footer-page>
    </div>
  </div>
</template>

<script>
import navTabPart1 from "@/components/common/nav/navTab_part1.vue";
import footerPage from "@/components/common/footer/footerPage.vue";
export default {
  name: "message",
  data() {
    return {
      MsgList:[],//消息列表
      singleMsg:'',//单个消息
      isActive:null,//点击选中的索引
      pageIndex: 1,//页码
      pageSize: 15,//页面容量
    };
  },
  computed:{
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  components: {
    navTabPart1,
    footerPage,
  },
  created() {
    this.init()
  },
  methods: {
    //初始化数据
    init(){
      this.$axios('/API/Get_Message_Pagination',{
        Version:'',
        PageIndex:this.pageIndex,
        PageSize:this.pageSize,
        JZToken: this.userinfo.TokenCode,
        UserGuid: this.userinfo.UserGuid
      }).then( res => {
        this.MsgList = res.data
      })
    },
    // 点击查看信息
    lookmsg(index){
      let msg = this.MsgList[index]
      this.isActive = index
      this.$axios('/API/GetSingleMessage',{
        Version:'',
        FLnkID: msg.FLnkID,
        JZToken: this.userinfo.TokenCode,
        UserGuid: this.userinfo.UserGuid
      }).then( res => {
        this.singleMsg = res.data
        this.init()
      })

    },
    //监听滚动 加载更多数据
    getScroll(e){
      const isTrue = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight
      if(isTrue){//判断是否触底，触底加载更多
        this.pageIndex = ++this.pageIndex
        this.$axios('/API/Get_Message_Pagination',{
          Version:'',
          PageIndex:this.pageIndex,
          PageSize:this.pageSize,
          JZToken:this.userinfo.TokenCode,
          UserGuid:this.userinfo.UserGuid
        }).then(res => {
          this.MsgList = this.MsgList.concat(res.data)
        })
      }
    },
   
    //跳转首页
    toindex() {
      this.$router.push("/home");
    },
    goBack(){
      this.$router.go(-1)
    }
    
  }
}
</script>

<style lang="scss">
@media(min-width: 1201px){
  @import "message.scss";
}
@media(max-width: 1200px){
  @import 'message_phone.scss';
}
</style>